@use "scss/functions";
@use "scss/variables";

.wrap {
  display: flex;
  margin: 0 auto;
  width: functions.get-rem(915px);
  padding: functions.get-rem(16px);
  background: variables.$surface-1;
  border-radius: functions.get-rem(8px);
}

.img-wrap {
  flex-shrink: 0;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  width: functions.get-rem(340px);
  background-color: variables.$additional-11-200;

  img {
    width: functions.get-rem(240px);
  }
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 20px;
  color: variables.$foreground-medium !important;
}
