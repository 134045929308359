@use "scss/mixins";
@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.menu {
  width: functions.get-rem(235px);
  margin-bottom: functions.get-rem(40px);

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  font-weight: 500 !important;
  color: variables.$foreground-medium !important;

  &:global {

    &.disabled {
      cursor: default !important;
      color: variables.$foreground-disabled !important;
      pointer-events: visible!important;
      background: transparent!important;

      & > svg {
        color: variables.$foreground-disabled !important;
      }
    }

  }


  & > svg,
  img {
    margin-left: 0 !important;
    font-size: 20px !important;
    color: variables.$foreground-medium !important;

    path {
      stroke-width: 1.3 !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &:hover {
    background-color: variables.$additional-13-100 !important;
  }

  &.active {
    color: variables.$foreground-high !important;

    & > svg,
    img {
      color: variables.$foreground-high !important;
    }
  }
}

.title {
  color: variables.$foreground-medium;
  padding: map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 2);
}
