@use "scss/variables";
@use "scss/functions";

$circle-size: 48px;
$pill-height: 80px;
$divider-gap: 16px;

.step {
  margin-top: 20px;

  .item {
    display: flex;
    padding-bottom: 12px;
    position: relative;

    .point {
      min-width: $circle-size;
      display: flex;
      align-items: center;
      height: $circle-size;
      width: $circle-size;

      border-radius: 50%;

      svg {
        margin: 0 auto;
      }

      svg {
        stroke-width: 1.5px !important;

        path {
          stroke-width: 1.5px !important;
        }
      }
    }

    .info {
      min-width: 0;
      border-radius: 5px;
      flex: 1;
      padding: functions.get-rem(24px);
      margin-left: functions.get-rem(34px);
      p {
        font-size: functions.get-rem(14px);
        line-height: 1.6;
        color: variables.$foreground-medium;
      }
    }

    &.waiting {
      .point {
        background: variables.$element-disabled;
        border: 2px solid variables.$element-disabled;
        color: variables.$foreground-disabled;
      }

      .info {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
      }
    }

    &.done {
      .point {
        background: variables.$positive-050;
        color: variables.$positive-500;
      }

      .info {
        border: 1px solid variables.$stroke-high;
      }
    }

    &.progress {
      .point {
        color: variables.$primary-1;
        background: variables.$secondary-1;
      }

      .info {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
      }
    }

    &.undone {
      .point {
        background: variables.$warning-050;
        color: variables.$warning-500;
      }

      .info {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
      }
    }

    &.pending-transaction {
      .point {
        background: variables.$additional-1-550;
        border: none;
        color: variables.$foreground-medium;
      }
    }

    &.committed-transaction {
      .point {
        background: variables.$positive-300;
        border: none;
        color: variables.$positive-900;

        svg > * {
          stroke: variables.$positive-900;
        }
      }
    }

    &.noSubscription {
      .point {
        background-color: variables.$element-disabled;
        color: variables.$foreground-disabled;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .info {
        border-radius: 4px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.15);
      }
    }

    &:after {
      content: "";
      background-color: variables.$stroke-high;
      position: absolute;
      width: 1px;
      height: calc(100% - $circle-size - $divider-gap);
      bottom: calc($divider-gap / 2);
      left: calc($circle-size / 2);
      display: block;
    }

    &:last-child:after {
      display: none;
    }

    .stepImportant {
      font-size: functions.get-rem(12px);
      line-height: 1.3;
      padding: 2px 16px;
      background: variables.$warning-050;
      display: inline-block;
      border-radius: 8px;
    }
  }

  .itemPill {
    @extend .item;

    .pointPill {
      min-width: 40px;
      display: flex;
      align-items: center;
      width: $circle-size;
      height: $pill-height !important;
      border-radius: 48px;

      svg {
        margin: 0 auto;
      }

      svg {
        stroke-width: 1.5px !important;

        path {
          stroke-width: 1.5px !important;
        }
      }
    }

    &:after {
      height: calc(100% - $pill-height - $divider-gap);
    }

    &.pending-transaction {
      .pointPill {
        background: variables.$additional-1-550;
        border: none;
        color: variables.$foreground-medium;
      }
    }

    &.committed-transaction {
      .pointPill {
        background: variables.$positive-300;
        border: none;
        color: variables.$positive-900;

        svg > * {
          stroke: variables.$positive-900;
        }
      }
    }
  }
}
