@use "src/scss/variables";
@use "src/scss/functions";
.wrap {
  color: variables.$foreground-low;
  font-size: functions.get-rem(14px);
  span {
    font-size: functions.get-rem(18px);
    display: flex;
    margin-right: 4px;
  }
  .error {
    color: variables.$critical-500;
  }
  .info {
    color: variables.$foreground-low;
  }
  .disabled {
    color: variables.$foreground-disabled !important;
  }
}
