@use "scss/variables";

.elementBackground {
  background-color: variables.$element-1;
}

.borderBottom {
  border-bottom: 1px solid variables.$stroke-high;
}

.imageContainer {
  width: 222px;
}
